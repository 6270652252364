<template>
  <div class="flex flex-col justify-center items-center">
    <SignupHeader :step="$t('global_step_02_02')" :title="$t('confirmation_section_title')" back="join" />
    <div class="mt-10 px-10 pt-20 pb-16 w-full bg-white rounded-lg shadow-lg flex flex-col justify-between items-center font-proximaMedium">
      <div class="flex flex-col justify-between items-center">
        <img
          class="h-24 mb-12 mx-auto md:mx-0"
          src="@/assets/images/mail-icon-cl.png"
          alt="mail-icon-cl"
        />
        <h1 class="text-xl">Your request has been sent to the PerPETual team.</h1>
        <h3 class="mt-4">You will receive an email when your request is approved.</h3>
      </div>
      <div>
        <h5 class="mt-12 text-sm">Find out more at the <a class="text-primary" href="#">Help Center</a></h5>
      </div>
    </div>
  </div>
</template>

<script>
import SignupHeader from "../primaryregistration/SignupHeader.vue";

export default {
  components: {
    SignupHeader,
  },
  computed: {
    socialSignup() {
      return this.$store.state.regType;
    },
  },
};
</script>

<style>
</style>