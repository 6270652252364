<template>
  <div class="toggle-button-cover">
    <div class="button-cover">
      <div class="button r" id="button-1">
        <input type="checkbox" :checked="compChecked" @change="valChange(val)" class="checkbox" />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    val: {
      type: Object
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedInput: this.checked
    }
  },
  computed: {
    compChecked() {
      if (this.checkAll == false) {
        return this.checked;
      } else {
        return this.checkAll;
      }
    },
  },
  methods: {
    valChange(val) {
      this.checkedInput = !this.checkedInput
      Object.keys(val).length === 0
        ? ""
        : this.$emit("inputVal", val, this.checkedInput);
    },
  },
};
</script>

<style scoped>
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 100px;
  box-sizing: border-box;
}

.button-cover {
  height: max-content;
  margin: 0;
  border-radius: 4px;
}

.button-cover:before {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 67px;
  height: 26px;
  margin: 0 auto 0 auto;
  overflow: hidden;
  border: 1px solid #9ca3af;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.knobs {
  z-index: 0;
}

.layer {
  width: 100%;
  background-color: #fff;
  transition: 0.3s ease all;
  z-index: -1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #f7941d;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked+.knobs:before {
  content: "";
  left: 42px;
  background-color: #0ac0cb;
}

#button-1 .checkbox:checked~.layer {}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}
</style>